<template>
	<v-ons-alert-dialog
		v-if="showVariablePriceModel"
		modifier="rowfooter"
		:visible.sync="showVariablePriceModel"
	>
		<span slot="title">{{ selectedVariation.name }}</span>


		<div v-if="selectedVariation.batch.length">
			<p
				style="color: #FF8C00; font-size: 13px;"
			>
				Batch
			</p>
			<v-ons-select
				v-model="selectedBatch"
				class="w-100 mb-4"
				@change="selectBatch"
			>
				<option
					v-for="(batch, index) in selectedVariation.batch"
					:key="index"
					:value="batch.id"
				>
					Batch: {{ batch.id }} | MRP: {{ batch.price | currency({ symbol: merchant.customAttributes.currency_symbol? merchant.customAttributes.currency_symbol:'' }) }}
				</option>
			</v-ons-select>
		</div>
		<v-ons-input
			:value="price = selectedVariation.price"
			type="number"
			step=".01"
			name="price"
			:readonly="selectedVariation.pricing_type != 'variable'"
			placeholder="Price"
			class="w-100"
			float
			@input="price = $event.target.value"
		/>
		<ValidationObserver ref="validator">
			<ValidationProvider
				v-slot="{ errors }"
				name="quantity"
				:rules="{
					numeric: selectedVariation.unit_measure_type === 'count',
					decimal: getUnitDecimalPlaces(selectedVariation.unit_measure_type)
				}"
			>
				<v-ons-input
					v-model="selectedVariation.quantity"
					type="number"
					min_value="0"
					name="quantity"
					:max_value="1000"
					placeholder="Quantity"
					class="w-100 mt-4"
					float
				/>
				<small class="text-danger">
					{{ errors[0] }}
				</small>
			</ValidationProvider>
		</validationobserver>
		<template slot="footer">
			<div class="px-2">
				<v-ons-alert-dialog-button
					:disabled="disableSubmit"
					@click="addVariationItem"
				>
					Submit
				</v-ons-alert-dialog-button>
				<v-ons-alert-dialog-button
					@click="closeDialog"
				>
					Cancel
				</v-ons-alert-dialog-button>
			</div>
		</template>
	</v-ons-alert-dialog>
</template>
<script>
	import { ValidationObserver, ValidationProvider } from "vee-validate"

	export default {
		name: 'PriceVaribaleDialog',
		components: {
			ValidationObserver,
			ValidationProvider
		},
		data() {
			return {
				disableSubmit: false,
				price: 0.1,
				selectedBatch:null
			}
		},
		computed: {
			pageStack: {
				get() {
					return this.$store.state.pageStack
				},
				set(pageStack) {
					this.$store.commit('setPageStack', pageStack)
				}
			},
			showVariablePriceModel: {
				get() {
					return this.$store.state.showVariablePriceModel
				},
				set(data) {
					this.$store.commit('setShowVariablePriceModel', data)
				}
			},
			selectedItem: {
				get() {
					return this.$store.state.selectedItem
				},
				set(item) {
					this.$store.commit('setState', {
						key: 'selectedItem',
						value: item
					})
				}
			},
			selectedVariation: {
				get() {
					return this.$store.state.selectedVariation
				},
				set(variation) {
					this.$store.commit('setSelectedVariation', variation)
				}
			},
			cartItems() {
				const cartItems = {}

				this.$store.state.cart.items.forEach(i => cartItems[`${i.id}-${i.price}`] = i)

				return cartItems
			},
			merchant() {
				return this.$store.state.merchant
			},
		},
		methods: {
			async addVariationItem(){
				let min_value = this.selectedVariation.custom_attributes.min_price || 0.1
				let max_value = this.selectedVariation.custom_attributes.max_price || this.price
				if((min_value <= this.price  && this.price <= max_value)){
					if (await this.$refs.validator.validate()) {
						this.selectedVariation.quantity = parseFloat(this.selectedVariation.quantity)
						this.selectedVariation.quantity = parseFloat(this.selectedVariation.quantity.toFixed(
							this.getUnitDecimalPlaces(this.selectedVariation.unit_measure_type)
						))
						this.selectedVariation.price = Number(this.price)
						this.selectedVariation.quantity = parseFloat(this.selectedVariation.quantity)
						this.$store.dispatch('modifyCart', {
							item: {
								id: this.selectedItem.item_id,
								name: this.selectedItem.name,
								category_id: this.selectedItem.category.id
							},
							variation: this.selectedVariation
						})
						this.showVariablePriceModel = false
					}
				} else {
					if ((min_value >= this.price  || this.price >= max_value) ) {
						this.$ons.notification.toast(`Price range should be below min price or above max price `, {
							timeout: 3000
						})
					}
					this.disableSubmit = true
				}
			},
			closeDialog(){
				this.showVariablePriceModel = false
				if(this.cartItems[`${this.selectedVariation.id}-${this.selectedVariation.price}`]){
					this.selectedVariation.quantity = this.cartItems[`${this.selectedVariation.id}-${this.selectedVariation.price}`].quantity
				} else {
					this.selectedVariation.quantity = 0
				}
			},
			selectBatch() {
				if (this.selectedBatch) {
					const batch = this.selectedVariation.batch.find(b => b.id === this.selectedBatch)
					this.selectedVariation.batch_id = this.selectedBatch
					this.selectedVariation.price = batch.price
					this.selectedVariation.custom_attributes.mrp = batch.mrp
				} else {
					this.selectedVariation.batch_id = null
					this.selectedVariation.price = this.selectedVariation.original_price
					this.selectedVariation.custom_attributes.mrp = this.selectedVariation.custom_attributes.original_mrp
				}

			}
		}
	}
</script>

export default app => {
	let isBackgroundSync = false

	const errorHandler = err => {
			if (err.response && [401, 410].includes(err.response.status)) {
				// if (err.response.status === 410) {
				// app.store.commit('setState', {
				// key: 'loginToken',
				// value: null,
				// save: false
				// })
				// }

				app.store.dispatch('logout')

				return true
			}

			return false
		}

	const getSyncOptions = model => {
		const deviceId = +app.$bridge.getLocalStorage('deviceId')
		const locationId = +app.$bridge.getLocalStorage('locationId')
		let options = {}
		let updatedAt = null
		switch (model) {
			case 'merchantDetails':
				options = {
					model:'merchant-details',
					deviceId: deviceId,
					locationId:locationId,
					params: {
						location_id: locationId
					}
				}
				break

			case 'employees':
				updatedAt = app.$bridge.getLastUpdatedAt('Employee', deviceId)
				updatedAt = typeof updatedAt === 'string' ? JSON.parse(updatedAt) : updatedAt
				options = {
					model: 'employees',
					deviceId: deviceId,
					locationId:locationId,
					collection: 'Employee',
					params: {
						location_id: locationId,
						updated_at: app.getUpdatedAtDatetime(
							updatedAt ? updatedAt.updated_at : null
						),
						trash: true
					}
				}

				break
			case 'devices':
				updatedAt = app.$bridge.getLastUpdatedAt('Device', deviceId)
				updatedAt = typeof updatedAt === 'string' ? JSON.parse(updatedAt) : updatedAt
				options = {
					model: 'devices',
					deviceId: deviceId,
					locationId:locationId,
					collection: 'Device',
					params: {
						location_id: locationId,
						updated_at: app.getUpdatedAtDatetime(
							updatedAt ? updatedAt.updated_at : null
						),
						trash: true
					}
				}

				break
			case 'floors':
				updatedAt = app.$bridge.getLastUpdatedAt('Floor', deviceId)
				updatedAt = typeof updatedAt === 'string' ? JSON.parse(updatedAt) : updatedAt
				options = {
					model: 'floors',
					deviceId: deviceId,
					locationId:locationId,
					collection: 'Floor',
					params: {
						location_id: locationId,
						updated_at: app.getUpdatedAtDatetime(
							updatedAt ? updatedAt.updated_at : null
						),
						trash: true
					}
				}

				break
			case 'floorTables':
				updatedAt = app.$bridge.getLastUpdatedAt('Table', deviceId)
				updatedAt = typeof updatedAt === 'string' ? JSON.parse(updatedAt) : updatedAt
				options = {
					model: 'floorTables',
					deviceId: deviceId,
					locationId:locationId,
					collection: 'Table',
					params: {
						location_id: locationId,
						updated_at: app.getUpdatedAtDatetime(
							updatedAt ? updatedAt.updated_at : null
						),
						trash: true
					}
				}

				break
			case 'merchantCustomers':
				updatedAt = app.$bridge.getLastUpdatedAt('Customer', deviceId)
				updatedAt = typeof updatedAt === 'string' ? JSON.parse(updatedAt) : updatedAt
				options = {
					model: 'merchantCustomers',
					deviceId: deviceId,
					locationId:locationId,
					collection: 'Customer',
					params: {
						updated_at: app.getUpdatedAtDatetime(
							updatedAt ? updatedAt.updated_at : null
						),
						trash: true
					}
				}

				break
			case 'categories':
				updatedAt = app.$bridge.getLastUpdatedAt('Category', deviceId)
				updatedAt = typeof updatedAt === 'string' ? JSON.parse(updatedAt) : updatedAt
				options = {
					model: 'categories',
					deviceId: deviceId,
					locationId:locationId,
					collection: 'Category',
					params: {
						updated_at: app.getUpdatedAtDatetime(
							updatedAt ? updatedAt.updated_at : null
						),
						trash: true
					}
				}

				break
			case 'taxes':
				updatedAt = app.$bridge.getLastUpdatedAt('Tax', deviceId)
				updatedAt = typeof updatedAt === 'string' ? JSON.parse(updatedAt) : updatedAt
				options = {
					model: 'taxes',
					deviceId: deviceId,
					locationId:locationId,
					collection: 'Tax',
					params: {
						updated_at: app.getUpdatedAtDatetime(
							updatedAt ? updatedAt.updated_at : null
						),
						trash: true
					}
				}

				break
			case 'discounts':
				updatedAt = app.$bridge.getLastUpdatedAt('Discount', deviceId)
				updatedAt = typeof updatedAt === 'string' ? JSON.parse(updatedAt) : updatedAt
				options = {
					model: 'discounts',
					deviceId: deviceId,
					locationId:locationId,
					collection: 'Discount',
					params: {
						location_id: locationId,
						updated_at: app.getUpdatedAtDatetime(
							updatedAt ? updatedAt.updated_at : null
						),
						trash: true
					}
				}

				break
			case 'merchantPriceCategories':
				updatedAt = app.$bridge.getLastUpdatedAt('PriceCategory', deviceId)
				updatedAt = typeof updatedAt === 'string' ? JSON.parse(updatedAt) : updatedAt
				options = {
					model: 'merchantPriceCategories',
					deviceId: deviceId,
					locationId:locationId,
					collection: 'PriceCategory',
					params: {
						updated_at: app.getUpdatedAtDatetime(
							updatedAt ? updatedAt.updated_at : null
						),
						trash: true
					}
				}

				break
			case 'items':
				updatedAt = app.$bridge.getLastUpdatedAt('Item', deviceId)
				updatedAt = typeof updatedAt === 'string' ? JSON.parse(updatedAt) : updatedAt
				options = {
					model: 'items',
					deviceId: deviceId,
					locationId:locationId,
					collection: 'Item',
					params: {
						location_id: locationId,
						updated_at: app.getUpdatedAtDatetime(
							updatedAt ? updatedAt.updated_at : null
						),
						trash: true
					}
				}

				break
			case 'itemVariationGroups':
				updatedAt = app.$bridge.getLastUpdatedAt('ItemVariationGroup', deviceId)
				updatedAt = typeof updatedAt === 'string' ? JSON.parse(updatedAt) : updatedAt
				options = {
					model: 'itemVariationGroups',
					deviceId: deviceId,
					locationId:locationId,
					collection: 'ItemVariationGroup',
					params: {
						location_id: locationId,
						updated_at: app.getUpdatedAtDatetime(updatedAt ? updatedAt.updated_at : null),
						trash: true
					}
				}

				break
			case 'merchantPaymentMethods':
				updatedAt = app.$bridge.getLastUpdatedAt('PaymentMethod', deviceId)
				updatedAt = typeof updatedAt === 'string' ? JSON.parse(updatedAt) : updatedAt
				options = {
					model: 'merchantPaymentMethods',
					deviceId: deviceId,
					locationId:locationId,
					collection: 'PaymentMethod',
					params: {
						updated_at: app.getUpdatedAtDatetime(
							updatedAt ? updatedAt.updated_at : null
						),
						trash: true
					}
				}
				break
			case 'settings':
					options = {
						model: 'settings',
						deviceId: deviceId,
						locationId:locationId,
					}
				break

			default:
				break
		}

		return options
	}

	const sync = ({ model,deviceId, locationId, collection,  params, silent, page = 1 }) => {
		if (app.$offline.state === 'up') {
			if (!silent && page === 1 && model!=='merchant-details') {
				app.store.commit('setSyncModels', {
					...app.store.state.syncModels,
					[model]: {
						...app.store.state.syncModels[model],
						percent: 0
					}
				})
			}

			return new Promise((resolve, reject) => {
				app.$axios.get(`/api/pos/v1/sync/${model}`, {
					params: Object.assign({}, {
						page: page,
						limit: 500
					}, params)
				}).then(response => {
					if (collection) {
						app.$bridge.customInsertOrUpdate(
							collection, deviceId, locationId, app.$bridge.getName() === 'ANDROID' ?
								JSON.stringify(response.data.data[model]) : response.data.data[model]
						)

						if (!silent && page < response.data.data.pagination.lastPage) {
							app.store.commit('setSyncModels', {
								...app.store.state.syncModels,
								[model]: {
									...app.store.state.syncModels[model],
									percent: Math.floor((page / response.data.data.pagination.lastPage) * 100)
								}
							})

							return sync({
								model: model,
								collection: collection,
								deviceId: deviceId,
								params: params,
								page: ++page
							}).then(() => resolve())
						} else if (!silent) {
							app.store.commit('setSyncModels', {
								...app.store.state.syncModels,
								[model]: {
									...app.store.state.syncModels[model],
									percent: 100
								}
							})
							setTimeout(resolve, 1000)
						}
					} else if (model === 'settings') {
						app.store.commit('setSettings', response.data.data)
						app.$bridge.setLocalStorage('settings', JSON.stringify(response.data.data))
						if (!silent) {
							app.store.commit('setSyncModels', {
								...app.store.state.syncModels,
								[model]: {
									...app.store.state.syncModels[model],
									percent: 100
								}
							})
						}
						setTimeout(resolve, 1000)
					}else if (model==='merchant-details') {
						app.store.commit('setSettings', response.data.data.merchant.settings)
						delete response.data.data.merchant.settings
						app.i18n.locale = response.data.data.location.languageCode || response.data.data.merchant.languageCode
						app.store.commit('setState', {
							key: 'merchant',
							value: response.data.data.merchant,
							save: true
						})
						app.store.commit('setState', {
							key: 'location',
							value: response.data.data.location,
							save: true
						})
						if (response.data.data.merchant.logoUrl) {
							app.getDataURL(response.data.data.merchant.logoUrl).then(dataURL => {
								app.$bridge.setLocalStorage('merchantLogo', dataURL)
							}).catch(err => console.error(err))
						}
						if (!silent){
							model='merchantDetails'
							app.store.commit('setSyncModels', {
								...app.store.state.syncModels,
								[model]: {
									...app.store.state.syncModels[model],
									percent: 100
								}
							})
						}

						setTimeout(resolve, 1000)
					}

				}).catch(err => {
					console.error(err)

					if (errorHandler(err))
						return false

					if (!silent) {
						app.store.commit('setSyncModels', {
							...app.store.state.syncModels,
							[model]: {
								...app.store.state.syncModels[model],
								percent: 100
							}
						})
					}

					reject()
				})
			})
		} else {
			this.$ons.notification.toast('Please connect to internet and try again.', {
				timeout: 5000
			})

			return Promise.resolve()
		}
	}

	const syncAll = () => {
		return sync(getSyncOptions('merchantDetails'))
		.then(() => sync(getSyncOptions('employees')))
		.then(() => {
			if (!['restaurant', 'qsr'].includes(app.store.state.merchant.businessType))
				return Promise.resolve()

			return sync(getSyncOptions('devices'))
		}).then(() => {
			if (app.store.state.merchant.businessType !== 'restaurant')
				return Promise.resolve()

			return sync(getSyncOptions('floors'))
		}).then(() => {
			if (app.store.state.merchant.businessType !== 'restaurant')
				return Promise.resolve()

			return sync(getSyncOptions('floorTables'))
		})
		.then(() => sync(getSyncOptions('merchantCustomers')))
		.then(() => sync(getSyncOptions('categories')))
		.then(() => sync(getSyncOptions('taxes')))
		.then(() => sync(getSyncOptions('discounts')))
		.then(() => sync(getSyncOptions('merchantPriceCategories')))
		.then(() => sync(getSyncOptions('items')))
		.then(() => sync(getSyncOptions('itemVariationGroups')))
		.then(() => sync(getSyncOptions('merchantPaymentMethods')))
		.catch(err => console.error(err))
	}

	const preSyncProcess = syncData => {
		const deviceId = +app.$bridge.getLocalStorage('deviceId')

		return new Promise((resolve) => {
			switch (syncData.model_name) {
				case 'cash-drawer-shift-event':
					let cashDrawerShift = app.$bridge.getCashDrawerShifts(deviceId, JSON.stringify({
						id: syncData.payload.cash_drawer_shift_id
					}))

					cashDrawerShift = (typeof cashDrawerShift === 'string' ?
						JSON.parse(cashDrawerShift) : cashDrawerShift).data[0]

					if (cashDrawerShift)
						syncData.payload.cash_drawer_shift_id = cashDrawerShift.cash_drawer_shift_id

					resolve(syncData)
					break
				case 'order':
					if (syncData.payload.orders[0].reservation_id) {
						let reservation = app.$bridge.getRecord('Reservation', deviceId, JSON.stringify([{
							key: 'id',
							value: syncData.payload.orders[0].reservation_id
						}]))

						reservation = typeof reservation === 'string' ? JSON.parse(reservation) : reservation

						if (reservation)
							syncData.payload.orders[0].reservation_id = reservation.reservation_id
					}

					resolve(syncData)
					break
				case 'order-refund':
					let order = app.$bridge.getOrders(deviceId, JSON.stringify({
						id: syncData.payload.order_id
					}))

					order = (typeof order === 'string' ? JSON.parse(order) : order).data[0]

					if (order)
						syncData.payload.order_id = order.order_id

					resolve(syncData)
					break
				case 'customer-credit':
					if (syncData.payload.customer_id) {
						let customer = app.$bridge.getCustomers(deviceId, JSON.stringify({
							id: syncData.payload.customer_id
						}))

						customer = (typeof customer === 'string' ? JSON.parse(customer) : customer)
							.data[0]

						if (customer) {
							syncData.payload.merchant_customer_id = customer.customer_id
							delete syncData.payload.customer_id
						}
					}

					resolve(syncData)
					break
				default:
					resolve(syncData)
					break
			}
		})
	}

	const postSyncProcess = (modelId, modelName, result) => {
		const date = new Date()
		const deviceId = +app.$bridge.getLocalStorage('deviceId')

		return new Promise((resolve) => {
			switch (modelName) {
				case 'employee-shift':
					let employeeShift = app.$bridge.getEmployeeShifts(deviceId, JSON.stringify({
						id: modelId
					}))

					employeeShift = typeof employeeShift === 'string' ?
						JSON.parse(employeeShift) : employeeShift
					employeeShift = employeeShift ? employeeShift[0] : null

					if (employeeShift) {
						employeeShift = Object.assign({}, employeeShift, {
							updated_at: date,
							is_synced:  !!employeeShift.clock_out
						})
						app.$bridge.insert('EmployeeShift', app.$bridge.getName() === 'ANDROID' ?
							JSON.stringify(employeeShift) : employeeShift, true
						)
					}

					resolve()
					break
				case 'cash-drawer-shift':
					let cashDrawerShift = app.$bridge.getCashDrawerShifts(deviceId, JSON.stringify({
						id: modelId
					}))

					cashDrawerShift = (typeof cashDrawerShift === 'string' ?
						JSON.parse(cashDrawerShift) : cashDrawerShift).data[0]

					if (cashDrawerShift) {
						cashDrawerShift = {
							id: cashDrawerShift.id,
							cash_drawer_shift_id: result.cashDrawerShift.id,
							updated_at: date,
							is_synced: !!cashDrawerShift.closed_at
						}
						app.$bridge.insert('CashDrawerShift', app.$bridge.getName() === 'ANDROID' ?
							JSON.stringify(cashDrawerShift) : cashDrawerShift, true)
					}

					resolve()
					break
				case 'order':
					let order = app.$bridge.getOrders(deviceId, JSON.stringify({ id: modelId }))
					order = (typeof order === 'string' ? JSON.parse(order) : order).data[0]

					if (order) {
						order = {
							id: order.id,
							order_id: result.order.id,
							updated_at: date,
							is_synced: true
						}
						app.$bridge.insert('Order', app.$bridge.getName() === 'ANDROID' ?
							JSON.stringify(order) : order, true)

						if (result.order.customers.length) {
							let customer = app.$bridge.getCustomers(deviceId, JSON.stringify({
								id: parseInt(result.order.customers[0].customer.phone)
							}))

							customer = (typeof customer === 'string' ? JSON.parse(customer) : customer).data[0]

							if (customer && !customer.customer_id) {
								customer = {
									id: customer.id,
									customer_id: result.order.customers[0].customer.id,
									updated_at: date
								}
								app.$bridge.insert('Customer', app.$bridge.getName() === 'ANDROID' ?
									JSON.stringify(customer) : customer, true)
							}
						}
					}

					resolve()
					break
				case 'cash-drawer-shift-event':
					let cashDrawerShiftEvent = app.$bridge.getRecord(
						'CashDrawerShiftEvent', deviceId, JSON.stringify([
							{ key: 'id', value: modelId }
						])
					)

					cashDrawerShiftEvent = typeof cashDrawerShiftEvent === 'string' ?
						JSON.parse(cashDrawerShiftEvent) : cashDrawerShiftEvent

					if (cashDrawerShiftEvent) {
						cashDrawerShiftEvent = {
							id: cashDrawerShiftEvent.id,
							updated_at: date,
							is_synced: true
						}
						app.$bridge
							.insert('CashDrawerShiftEvent', app.$bridge.getName() === 'ANDROID' ?
							JSON.stringify(cashDrawerShiftEvent) : cashDrawerShiftEvent, true)
					}

					resolve()
					break
				case 'customer-credit':
					let credit = app.$bridge.getRecord(
						'Credit', deviceId, JSON.stringify([
							{ key: 'id', value: modelId }
						])
					)

					credit = typeof credit === 'string' ? JSON.parse(credit) : credit

					if (credit) {
						credit = {
							id: credit.id,
							updated_at: date,
							is_synced: true
						}
						app.$bridge.insert('Credit', app.$bridge.getName() === 'ANDROID' ?
							JSON.stringify(credit) : credit, true)
					}

					resolve()
					break
				case 'order-refund':
					let refund = app.$bridge.getRecord(
						'OrderRefund', deviceId, JSON.stringify([
							{ key: 'id', value: modelId }
						])
					)

					refund = typeof refund === 'string' ? JSON.parse(refund) : refund

					if (refund) {
						refund = {
							id: refund.id,
							updated_at: date,
							is_synced: true
						}
						app.$bridge.insert('OrderRefund', app.$bridge.getName() === 'ANDROID' ?
							JSON.stringify(refund) : refund, true)
					}

					resolve()
					break
				case 'reservation':
					let reservation = app.$bridge.getRecord(
						'Reservation', deviceId, JSON.stringify([
							{ key: 'id', value: modelId }
						])
					)

					reservation = typeof reservation === 'string' ?
						JSON.parse(reservation) : reservation

					if (reservation) {
						reservation.reservation_id = result.reservation.id
						app.$bridge.insert('Reservation', app.$bridge.getName() === 'ANDROID' ?
							JSON.stringify(reservation) : reservation, true)

						if (result.reservation.customer) {
							let customer = app.$bridge.getCustomers(deviceId, JSON.stringify({
								id: parseInt(result.reservation.customer.phone)
							}))

							customer = (typeof customer === 'string' ? JSON.parse(customer) : customer).data[0]

							if (customer && !customer.customer_id) {
								customer = {
									id: customer.id,
									customer_id: result.reservation.customer.id,
									updated_at: date
								}
								app.$bridge.insert('Customer', app.$bridge.getName() === 'ANDROID' ?
									JSON.stringify(customer) : customer, true)
							}
						}
					}

					resolve()
					break
				default:
					resolve()
					break
			}
		})
	}

	const backgroundSync = () => {
		setInterval(() => {
			if (app.$offline.state === 'up' && !isBackgroundSync && app.store.state.isLoggedIn) {
				let syncData = app.$bridge.getSyncData()

				syncData = typeof syncData === 'string' ? JSON.parse(syncData) : syncData

				if (syncData && syncData.attempts < 3) {
					isBackgroundSync = true
					preSyncProcess(syncData).then(syncData => {
						return app.$axios.post(`/api/pos/v1/sync/${syncData.model_name}`, syncData.payload)
					}).then(response => {
						return postSyncProcess(
							syncData.model_id, syncData.model_name, response.data.data
						)
					}).then(() => {
						app.$bridge.deleteRecord('Sync', syncData.id)
						isBackgroundSync = false
					}).catch(err => {
						if (err.response) {
							if (errorHandler(err))
								return false

							const data = {
								id: syncData.id,
								attempts: syncData.attempts + 1
							}

							app.$bridge.insert('Sync', app.$bridge.getName() === 'ANDROID' ?
								JSON.stringify(data) : data, true)
						}

						isBackgroundSync = false
					})
				} else if (syncData) {
					isBackgroundSync = true
					app.$axios.post('/api/pos/v1/sync/failed', {
						model_id: syncData.model_id,
						model_name: syncData.model_name,
						payload: syncData.payload
					}).then(() => {
						app.$bridge.deleteRecord('Sync', syncData.id)
						isBackgroundSync = false
					}).catch(err => {
						if (errorHandler(err))
							return false

						isBackgroundSync = false
					})
				}
			}
		}, 3000)
	}

	return {
		getSyncOptions,
		sync,
		syncAll,
		backgroundSync
	}
}

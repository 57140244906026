const state = () => ({
	appVersion: process.env.VERSION,
	mainMenu: [],
	pageStack: [],
	syncModels: {
		merchantDetails:{
			name: 'Merchant Details',
			icon: 'fa-cog',
			sync: true
		},
		employees: {
			name: 'Employees',
			modelName: 'Employee',
			icon: 'fa-user-tie',
			percent: null
		},
		devices: {
			name: 'Devices',
			modelName: 'Device',
			icon: 'fa-mobile',
			percent: null
		},
		floors: {
			name: 'Floors',
			modelName: 'Floor',
			icon: 'fa-building',
			percent: null
		},
		floorTables: {
			name: 'Tables',
			modelName: 'Table',
			icon: 'fa-utensils',
			percent: null
		},
		merchantCustomers: {
			name: 'Customers',
			modelName: 'Customer',
			icon: 'fa-users',
			percent: null
		},
		categories: {
			name: 'Categories',
			modelName: 'Category',
			icon: 'fa-sitemap',
			percent: null
		},
		taxes: {
			name: 'Taxes',
			modelName: 'Tax',
			icon: 'fa-file-invoice-dollar',
			percent: null
		},
		discounts: {
			name: 'Discounts',
			modelName: 'Discount',
			icon: 'fa-percent',
			percent: null
		},
		merchantPriceCategories: {
			name: 'Price Categories',
			modelName: 'PriceCategory',
			icon: 'fa-tags',
			percent: null
		},
		items: {
			name: 'Items',
			modelName: 'Item',
			icon: 'fa-list',
			percent: null
		},
		itemVariationGroups: {
			name: 'Item Variation Groups',
			modelName: 'ItemVariationGroup',
			icon: 'fa-edit',
			percent: null
		},
		merchantPaymentMethods: {
			name: 'Payment Methods',
			modelName: 'PaymentMethod',
			icon: 'fa-credit-card',
			percent: null
		}
	},
	uuid:null,
	deviceId: null,
	locationId: null,
	locationName: null,
	isLoggedIn: false,
	loginToken: null,
	merchant: {
		features: {}
	},
	location: {},
	subscription: null,
	settings: {
		sale: {},
		general: {},
		receipt: {},
		barcode: {}
	},
	employees: [],
	employee: null,
	cashDrawer: {
		show: false,
		close: true,
		type: ''
	},
	cashDrawerShift: null,
	selectedCategoryId: null,
	selectedPriceCategory: {
		id: null,
		name: 'Default'
	},
	selectedItem: null,
	selectedVariation: null,
	itemPopover: {
		show: false,
		target: null
	},
	showModifiers: false,
	favorites: [],
	cart: {
		items: [],
		price: {
			subTotal: 0,
			tax: 0,
			inclusiveTaxTotal: 0,
			exclusiveTaxTotal: 0,
			taxes: [],
			discount: 0,
			discounts: [],
			total:0
		},
		priceCategory: null,
		customer: {},
	},
	showConfirmWOPrint: true,
	printerSettings: {
		paperSize: 2,
		cutPaperAfterPrint: true,
		openCashboxAfterPrint: false,
		printShiftSummaryAfterClockout: true,
		printShiftSummaryCategoryItems: false,
		printShiftSummaryDenominations: false,
		reprintKot: false,
		scaleFactor: 2,
		paperFeedLength: 1
	},
	kots: [],
	selectedTable: null,
	selectedOrder: null,
	lastSelectedTableId: null,
	denominations: [],
	orderReceipt: {
		print: false,
		type: '',
		data: null
	},
	shiftReceipt: {
		print: false,
		source: ''
	},
	discounts: [],
	showDiscount: false,
	showOrderDiscounts: false,
	selectedDiscount: null,
	additionalInfo: {},
	onlineSelectedStartDate: null,
	onlineSelectedEndDate: null,
	selectedOnlineOrder: null,
	newOnlineOrdersCount: 0,
	onHold: [],
	isWaiterAppRunning: false,
	appMode: '',
	isWeighingScaleEnabled: false,
	isEzSwype: false,
	showVariablePriceModel: false,
	showCustomSale: false,
	selectedTables: [],
	onlineOrder:{
		getOnlineOrders : false
	},
	searchBarVisibility:false,
	loopNotificationSound:true,
	itemView:{
		toggleViews:false,
		items:false
	},
	updateModel:false,
	filters: {
		order_interim_state: null,
		channel: null,
		order_type: null,
		payment_method: null,
		transporter_id: null
	},
	wPosPaymentEnabled: false,
	totp: {
		dialog: false,
		value: '',
		type: 'verify-totp',
		title: 'Authentic',
		message: ''
	},
})

export default state

<template>
	<ons-page>
		<v-ons-toolbar>
			<div class="center">
				<p class="text-center m-0">
					Employees
				</p>
			</div>
			<div class="right">
				<v-ons-toolbar-button
					v-if="$bridge.getName() === 'ANDROID' && $offline.state === 'up'"
					icon="md-refresh"
					@click="$bridge.refresh && $bridge.refresh()"
				/>
			</div>
		</v-ons-toolbar>
		<div class="background bg-white" />
		<v-ons-list class="mb-5">
			<v-ons-list-header>Select Employee</v-ons-list-header>
			<v-ons-list-item
				v-for="(employee, index) in employees"
				:key="index"
				tappable
				@click="selectEmployee(employee)"
			>
				<div class="left">
					<v-ons-icon
						icon="fa-id-badge"
						class="list-item__icon text-muted"
					/>
				</div>
				<div class="center">
					<p class="m-0">
						{{ employee.name }}
					</p>
				</div>
				<div class="right">
					<small>
						{{ employee.roles.find(role => role.isAdmin === true) ? 'Full' : 'Limited' }} Access
					</small>
				</div>
			</v-ons-list-item>
		</v-ons-list>
		<v-ons-button
			modifier="large"
			class="rounded-0"
			style="position: fixed; bottom: 0"
			@click="isLogoutVisible = true"
		>
			Logout
		</v-ons-button>
		<v-ons-alert-dialog
			modifier="rowfooter"
			title="Logout Confirmation"
			:footer="{
				Ok: () => logout(),
				Cancel: () => isLogoutVisible = false
			}"
			:visible.sync="isLogoutVisible"
		>
			Are you sure want to logout?
		</v-ons-alert-dialog>
	</ons-page>
</template>

<script>
	import Passcode from '~/components/passcode'
	import Subscription from '~/components/subscription'

	export default {
		name: 'Employees',
		data() {
			return {
				isLogoutVisible : false
			}
		},
		computed: {
			employees() {
				return this.$store.state.employees
			},
			pageStack: {
				get() {
					return this.$store.state.pageStack
				},
				set(pageStack) {
					this.$store.commit('setPageStack', pageStack)
				}
			},
			subscription() {
				return this.$store.state.subscription
			}
		},
		mounted() {
			if (this.subscription.show_modal) {
				this.pageStack.push(Subscription)
			}
		},
		methods: {
			selectEmployee(employee) {
				this.$bridge.setLocalStorage('employee', JSON.stringify(employee))
				this.$store.commit('setState', {
					key: 'employee',
					value: employee,
					save: true
				})
				this.$emit('push', {
					extends: Passcode,
					onsNavigatorProps: {
						type: 'clockin'
					}
				})
			},
			logout(){
				this.isLogoutVisible = false
				this.$store.dispatch('logout')
			}
		}
	}
</script>

const actions = {
	nuxtServerInit ({ commit }, { req }) {
		commit('setState', {
			key: 'isLoggedIn',
			value: req.session && req.session.authorization ? true : false
		})
	},
	login(vuexContext, data) {
		return this.$axios
			.$post('/api/pos/v2/login', data.body, {
				params: data.query
			})
			.then(response => {
				if (response.status === 'success') {
					if (this.$sentry) {
						this.$sentry.configureScope(scope => {
							scope.setUser({
								device_id: response.data.info.device.id,
								id: response.data.info.merchant.id,
								username: response.data.info.username,
								email: response.data.info.email,
								location_id: response.data.info.location.id,
								location_name: response.data.info.location.name
							})
						})
					}
					vuexContext.commit('setState', {
						key: 'isLoggedIn',
						value: true,
						save: true
					})
					vuexContext.commit('setState', {
						key: 'deviceId',
						value: response.data.info.device.id,
						save: true
					})

					vuexContext.commit('setState', {
						key: 'locationId',
						value: response.data.info.location.id,
						save: true
					})

					vuexContext.commit('setState', {
						key: 'locationName',
						value: response.data.info.location.name,
						save: true
					})

					vuexContext.commit('setState', {
						key: 'location',
						value: response.data.info.location,
						save: true
					})


					vuexContext.commit('setState', {
						key: 'loginToken',
						value: response.data.token,
						save: true
					})
					vuexContext.commit('setState', {
						key: 'subscription',
						value: { 
							...response.data.info.merchant.subscription,
							device: response.data.info.device
						},
						save: true
					})
					let tempData = response.data.info
					let merchantInfo = {
            "id": tempData.merchant.id,
            "name": tempData.name,
            "email": tempData.email,
            "username": tempData.username,
            "websiteUrl": tempData.merchant.website_url.scalar,
            "logoUrl": tempData.merchant.logo_url,
            "countryCode": tempData.merchant.country_code,
            "languageCode": tempData.merchant.language_code,
            "currencyCode": tempData.merchant.currency_code,
            "businessName": tempData.merchant.business_name,
            "timezone": tempData.merchant.timezone,
            "businessAddress": tempData.merchant.business_address,
            "businessPhone": tempData.merchant.business_phone,
            "customAttributes": tempData.merchant.custom_attributes,
            "latitude": null,
            "longitude": null,
            "businessType": tempData.merchant.business_type.slug,
            "businessTypeId": tempData.merchant.business_type.id,
            "features": tempData.merchant.features
        	}
					vuexContext.commit('setState', {
						key: 'merchant',
						value: merchantInfo
					})
					vuexContext.commit('setSettings', tempData.merchant.settings)
					this.$bridge.setLocalStorage('isLoggedIn', 'true')
					this.$bridge.setLocalStorage('deviceId', response.data.info.device.id)
					this.$bridge.setLocalStorage('locationId', response.data.info.location.id)
					this.$bridge.setLocalStorage('locationName', response.data.info.location.name)
					this.$bridge.setLocalStorage(
						'settings', JSON.stringify(tempData.merchant.settings)
					)
					delete response.data.info.merchant.settings
					this.$bridge.setLocalStorage('merchant', JSON.stringify(merchantInfo))
					this.$i18n.locale = response.data.info.location.language_code || response.data.info.merchant.language_code

					if (merchantInfo.logoUrl) {
						this.app.getDataURL(merchantInfo.logoUrl).then(dataURL => {
							this.$bridge.setLocalStorage('merchantLogo', dataURL)
						}).catch(err => console.error(err))
					}
				}

				return response
			}).catch(e => console.console.error(e))
	},
	logout(vuexContext) {
		this.$axios.get('/api/pos/v1/logout').then(() => {
			vuexContext.dispatch('clearLocalStorage')
			window.location.reload()
		}).catch(() => {
			vuexContext.dispatch('clearLocalStorage')
			window.location.reload()
		})
	},
	clearLocalStorage() {
		const data = {}
		const preservedKeys = [
			'loginToken',
			'cashDrawerShift',
			'employee',
			'lastOrderDay',
			'orderCount',
			'printerSettings',
			'kots'
		]

		for (const i in preservedKeys) {
			data[preservedKeys[i]] = this.$bridge.getLocalStorage(preservedKeys[i])
		}

		this.$bridge.clearLocalStorage()

		for (const i in preservedKeys) {
			if (data[preservedKeys[i]])
				this.$bridge.setLocalStorage(preservedKeys[i], data[preservedKeys[i]])
		}
	},
	async getDeviceUuid(vuexContext) {
		const uuid = await this.$bridge.getDeviceUuid()

		vuexContext.commit('setState', {
			key: 'uuid',
			value: uuid
		})
	},
	initData(vuexContext) {
		vuexContext.dispatch('getDeviceUuid')
		if (this.$offline.state === 'up' && vuexContext.state.isLoggedIn === false){
			this.$bridge.setLocalStorage('isLoggedIn', 'false')
		}

		vuexContext.commit('setState', {
			key: 'isLoggedIn',
			value: this.$bridge.getLocalStorage('isLoggedIn') === 'true'
		})

		vuexContext.commit('setState', {
			key: 'loginToken',
			value: this.$bridge.getLocalStorage('loginToken')
		})

		vuexContext.commit('setState', {
			key: 'deviceId',
			value: +this.$bridge.getLocalStorage('deviceId')
		})
		vuexContext.commit('setState', {
			key: 'locationId',
			value: +this.$bridge.getLocalStorage('locationId')
		})
		vuexContext.commit('setState', {
			key: 'locationName',
			value: this.$bridge.getLocalStorage('locationName')
		})
		vuexContext.commit('setState', {
			key: 'isWaiterAppRunning',
			value: this.$bridge.getLocalStorage('isWaiterAppRunning') === 'true'
		})
		vuexContext.commit('setState', {
			key: 'isWeighingScaleEnabled',
			value: this.$bridge.getLocalStorage('isWeighingScaleEnabled') === 'true'
		})

		let subscription = this.$bridge.getLocalStorage('subscription')
		let merchant = this.$bridge.getLocalStorage('merchant')
		const settings = this.$bridge.getLocalStorage('settings')
		let employees = this.$bridge.getEmployees(vuexContext.state.deviceId, vuexContext.state.locationId)
		employees = typeof employees === 'string' ? JSON.parse(employees) : employees
		const employee = this.$bridge.getLocalStorage('employee')
		let cashDrawerShift = this.$bridge.getLocalStorage('cashDrawerShift')
		const categoryView = this.$bridge.getLocalStorage('categoryView')
		const showConfirmWOPrint = this.$bridge.getLocalStorage('showConfirmWOPrint')
		const favorites = this.$bridge.getLocalStorage('favorites')
		let location = this.$bridge.getLocalStorage('location')
		const onHold = this.$bridge.getLocalStorage('onHold')
		const printerSettings = this.$bridge.getLocalStorage('printerSettings')
		const kots = this.$bridge.getLocalStorage('kots')
		const ezSwype = this.$bridge.getLocalStorage('isEzSwype')
		const wPosPaymentEnabled = this.$bridge.getLocalStorage('wPosPaymentEnabled')
		const addPay = this.$bridge.getLocalStorage('isAddPay')
		const loopNotificationSound = this.$bridge.getLocalStorage('loopNotificationSound')
		const itemView = this.$bridge.getLocalStorage('itemView')
		vuexContext.commit('setState', {
			key: 'employees',
			value: employees
		})
		vuexContext.commit('setState', {
			key: 'newOnlineOrdersCount',
			value: +this.$bridge.getLocalStorage('newOnlineOrdersCount') || 0
		})


		if (subscription) {
			subscription = JSON.parse(subscription)
			vuexContext.commit('setState', {
				key: 'subscription',
				value: subscription
			})
			vuexContext.dispatch('checkSubscription')
		}

		if (ezSwype) {
			vuexContext.commit('setEzSwype', ezSwype === 'true')
		}else if(wPosPaymentEnabled){
			vuexContext.commit('setState', {
			key: 'wPosPaymentEnabled',
			value: wPosPaymentEnabled === 'true'
		})
		} else if (addPay) {
			vuexContext.commit('setState', {
				key: 'isAddPay',
				value: addPay
			})
		}

		if (settings)
			vuexContext.commit('setSettings', JSON.parse(settings))

		if (employee) {
			vuexContext.commit('setState', {
				key: 'employee',
				value: JSON.parse(employee)
			})
		}

		if (loopNotificationSound) {
			vuexContext.commit('setState', {
				key: 'loopNotificationSound',
				value: loopNotificationSound === 'true'
			})
		}
		if (itemView) {
			vuexContext.commit('setState', {
				key: 'itemView',
				value: JSON.parse(itemView)
			})
		}

		if (merchant) {
			merchant = JSON.parse(merchant)

			if (this.$sentry) {
				this.$sentry.configureScope(scope => {
					scope.setUser({
						device_id: vuexContext.state.deviceId,
						id: merchant.id,
						username: merchant.username,
						email: merchant.email,
						location_id: vuexContext.state.locationId,
						location_name: vuexContext.state.locationName
					})
				})
			}

			vuexContext.commit('setState', {
				key: 'merchant',
				value: merchant
			})

			if (merchant.languageCode)
				this.$i18n.locale = merchant.languageCode


		}

		if (cashDrawerShift) {
			cashDrawerShift = JSON.parse(cashDrawerShift)
			vuexContext.commit('setState', {
				key: 'cashDrawerShift',
				value: cashDrawerShift
			})
		}
		vuexContext.commit('setAppMode', this.$bridge.getAppMode())
		if (location){
			location = JSON.parse(location)
			vuexContext.commit('setState', {
				key: 'location',
				value: location
			})
			if (location.languageCode)
				this.$i18n.locale = location.languageCode
		}

		if (categoryView) {
			vuexContext.commit('setState', {
				key: 'categoryView',
				value: categoryView
			})
		}

		if (showConfirmWOPrint) {
			vuexContext.commit('setState', {
				key: 'showConfirmWOPrint',
				value: showConfirmWOPrint === 'true'
			})
		}

		if (favorites) {
			vuexContext.commit('setState', {
				key: 'favorites',
				value: JSON.parse(favorites)
			})
		}

		if (onHold) {
			vuexContext.commit('setState', {
				key: 'onHold',
				value: JSON.parse(onHold)
			})
		}

		if (printerSettings)
			vuexContext.commit('setPrinterSettings', JSON.parse(printerSettings))
			if (kots) {
				vuexContext.commit('setState', {
					key: 'kots',
					value: JSON.parse(kots)
				})
			}


		if (employee && !cashDrawerShift) {
			vuexContext.commit('setState', {
				key: 'cashDrawer',
				value: {
					show: true,
					close: false,
					type: 'starting'
				}
			})
		}
	},
	selectItem(vuexContext, item) {
		item = Object.assign({}, item)
		item.show = true

		const selectedPriceCategory = vuexContext.state.selectedPriceCategory

		if (item.pricing_type === 'multi' && selectedPriceCategory) {
			item = this.app.applyMultiPricing(
				item, selectedPriceCategory.id
			)
		}

		const cartItemVariation = this.app.filterItem(vuexContext.state.cart.items, item)

		if (cartItemVariation) {
			item.price = cartItemVariation.price
			item.quantity = cartItemVariation.quantity
		}

		vuexContext.commit('setState', {
			key: 'selectedItem',
			value: item
		})
	},
	modifyCart(vuexContext, { item, variation, triggerCalculation = true }) {
			const modifiers = variation.groups ? variation.groups.filter(g => g.type === 'modifier') : []
			const cartItems = JSON.parse(JSON.stringify(vuexContext.state.cart.items))

			let cartItem = this.app.filterItem(cartItems, variation)
			let itemDiscount = []
			let comboDiscount = []
			const itemDiscounts = vuexContext.getters.itemDiscounts().filter(d => {
				const discountItemIndex = d.discount_items.findIndex(i => {
					return i.variation_id === variation.id && i.buy_condition_value
				})

				return discountItemIndex !== -1
			})

			if (variation.price > 0 && variation.quantity > 0 && variation.quantity <= 100000) {
				if (itemDiscounts.length) {
					itemDiscount = itemDiscounts.reduce((discounts, discount) => {
						discount.discount_items.forEach(di => {
							const d = {
								...di,
								id: discount.id
							}

							if (di.variation_id === variation.id && di.get_discount_quantity === 0 &&
							di.get_discount_type) {
								d.type = 'ITEM'
								discounts.push(d)
							} else {
								d.type = 'COMBO'
								comboDiscount.push(d)
							}
						})

						return discounts
					}, [])
				}

				if (cartItem) {
					const index = this.app.filterItem(cartItems, variation, 'index')

					cartItem.quantity = variation.quantity
					cartItem.groups = cartItem.groups.map(group => {
						if (group.type === 'combo')
							group.quantity = variation.quantity

						return group
					})

					cartItems[index] = cartItem
				} else {
					cartItems.unshift({
						id: variation.id,
						item_id: item.id,
						category_id: item.category_id,
						inventory_id: variation.inventory_id,
						batch_id: variation.batch_id || null,
						kot_device_id: variation.kot_device_id,
						name: variation.name,
						item_name: item.name,
						alternate_name: variation.custom_attributes.alternate_language,
						sku: variation.sku,
						type: variation.type,
						barcode: variation.barcode,
						hsn: variation.custom_attributes.hsn || '',
						unit_measure_type: variation.unit_measure_type,
						mrp: parseFloat(variation.custom_attributes.mrp || 0),
						price: parseFloat(variation.price),
						quantity: variation.quantity,
						tax: variation.tax,
						discount: [],
						item_discount: itemDiscount,
						combo_discount: comboDiscount,
						itemization_type: variation.itemization_type || 'item',
						groups: (variation.groups || []).concat(
							variation.combo ? variation.combo.variations.map(v => ({
								item_variation_group_id: variation.combo.id,
								group_item_variation_id: v.id,
								type: 'combo',
								price: v.price,
								kot_device_id: v.kot_device_id,
								item_variation_name: v.name,
								alternate_name: v.alternate_name,
								unit_measure_type: v.unit_measure_type,
								quantity: 1,
								modifiers
							})) : []
						)
					})
				}

				// combo discount
				if (comboDiscount.length) {
					const buyItems = comboDiscount.filter(i => i.buy_condition_type.length !== 0)
					const getItems = comboDiscount.filter(i => i.get_discount_type.length !== 0)

					if (buyItems.length && getItems.length) {
						let addGetItemsFlag = true
						const buyItemsData = []

						buyItems.forEach(d => {
							const ci = cartItems.find(iv => {
								return iv.id === d.variation_id && iv.batch_id === d.buy_batch_id
							})


							if (!ci || (d.buy_condition_type === 'quantity' && d.buy_condition_value > ci.quantity) || (d.buy_condition_type === 'price' && d.buy_condition_value > ci.price * ci.quantity)) {
								addGetItemsFlag = false

								return false
							} else {
								buyItemsData.push(Math.floor(ci.quantity / d.buy_condition_value))
							}
						})

						getItems.forEach(d => {
							const discountQuantity = Math.min(...buyItemsData) * d.get_discount_quantity
							let variation = this.$bridge.getItemVariations(vuexContext.state.deviceId, JSON.stringify({
								id: d.variation_id
							}))

							variation = (typeof variation === 'string' ? JSON.parse(variation) : variation)[0]

							if (variation) {
								const batch = variation.batch.find(b => b.id === d.get_batch_id) || null

								if (addGetItemsFlag) {
									const cartItemIndex = cartItems.findIndex(iv => {
										return iv.id === variation.id && iv.batch_id === d.get_batch_id && iv.price === (
											batch ? batch.price : variation.price
										)
									})

									if (cartItemIndex !== -1) {
										const discountIndex = cartItems[cartItemIndex].item_discount
											.findIndex(discount => discount.id === d.id)

										if (discountIndex !== -1) {
											if (discountQuantity > cartItems[cartItemIndex].item_discount[discountIndex].quantity && cartItems[cartItemIndex].item_discount[discountIndex].quantity < d.get_discount_max_quantity) {
												cartItems[cartItemIndex].quantity += d.get_discount_quantity
												cartItems[cartItemIndex].item_discount[discountIndex].quantity += d.get_discount_quantity
											} else if (discountQuantity < cartItems[cartItemIndex].item_discount[discountIndex].quantity) {
												cartItems[cartItemIndex].quantity -= d.get_discount_quantity
												cartItems[cartItemIndex].item_discount[discountIndex].quantity -= d.get_discount_quantity
											}
										} else {
											cartItems[cartItemIndex].quantity += d.get_discount_quantity
											cartItems[cartItemIndex].item_discount.push({
												...d,
												type: 'COMBO',
												quantity: d.get_discount_quantity
											})
										}
									} else {
										cartItems.unshift({
											id: variation.id,
											item_id: variation.item_id,
											category_id: variation.item.category_id,
											inventory_id: variation.inventory_id,
											batch_id: d.get_batch_id,
											kot_device_id: variation.kot_device_id,
											name: variation.name,
											item_name: variation.item.name,
											alternate_name: variation.custom_attributes.alternate_language,
											sku: variation.sku,
											type: variation.type,
											barcode: variation.barcode,
											hsn: variation.custom_attributes.hsn || '',
											unit_measure_type: variation.unit_measure_type,
											mrp:  batch ? batch.mrp : (variation.custom_attributes.mrp || 0),
											price: batch ? batch.price : variation.price,
											quantity: d.get_discount_quantity,
											tax: variation.tax,
											discount: [],
											item_discount: [{
												...d,
												type: 'COMBO',
												quantity: d.get_discount_quantity
											}],
											combo_discount: [],
											itemization_type: variation.itemization_type || 'item',
											groups: []
										})
									}
								} else {
									const index = cartItems.findIndex(i => {
										return i.id === variation.id && i.batch_id === d.get_batch_id && i.price == (
											batch ? batch.price : variation.price
										)
									})

									if (index !== -1) {
										const discountIndex = cartItems[index].item_discount
											.findIndex(discount => discount.id === d.id)

										if (discountIndex !== -1)
											cartItems[index].item_discount.splice(discountIndex, 1)
									}
								}
							}
						})
					}
				}
			} else if (cartItem) {
				const index = this.app.filterItem(cartItems, variation, 'index')

				if (index !== -1) {
					const itemComboDiscount = cartItems[index].combo_discount

					cartItems.splice(index, 1)

					if (itemComboDiscount.length) {
						itemComboDiscount.filter(i => i.get_discount_type.length !== 0).forEach(d => {
							const index = cartItems.findIndex(i => i.id === d.variation_id)

							if (index !== -1) {
								const discountIndex = cartItems[index].item_discount.findIndex(id => id.id === d.id)

								if (discountIndex !== -1) {
									cartItems[index].quantity -= cartItems[index].item_discount[discountIndex].quantity
									cartItems[index].item_discount.splice(discountIndex, 1)

									if (cartItems[index].quantity <= 0)
										cartItems.splice(index, 1)
								}
							}
						})
					}
				}
			}

			vuexContext.commit('setCart', { items: cartItems })

			if (triggerCalculation)
				vuexContext.dispatch('cartCalculation')
		},
	cartCalculation(vuexContext, appliedAutoOrderDiscount = false) {
			let cartItems = JSON.parse(JSON.stringify(vuexContext.state.cart.items))
			let price = {
				subTotal: 0,
				tax: 0,
				inclusiveTaxTotal: 0,
				exclusiveTaxTotal: 0,
				taxes: {},
				discount: 0,
				discountedAmount: 0,
				discountedTax: 0,
				roundOff: 0,
				total: 0
			}

			cartItems = cartItems.map((item) => {
				let tax = 0
				let inclusiveTaxes = []
				let exclusiveTaxes = []
				let inclusivePercent = 0
				let exclusivePercent = 0
				const total = parseFloat((item.price * item.quantity).toFixed(2))

				item.subTotal = total
				item.discountedAmount = 0
				item.discountedTax = 0
				item.discounts = []

				item.tax.forEach(tax => {
					let taxData = {
						tax_id: tax.id,
						tax_name: tax.name,
						tax_rate: tax.rate,
						discounted_tax: 0
					}

					if (tax.inclusion_type === 'inclusive') {
						inclusiveTaxes.push(taxData)
						inclusivePercent += parseFloat(tax.rate)
					} else {
						taxData.tax_amount = parseFloat((total * (parseFloat(tax.rate) / 100)).toFixed(2))
						exclusiveTaxes.push(taxData)
						exclusivePercent += parseFloat(tax.rate)
					}
				})

				if (exclusivePercent > 0) {
					price.subTotal += total
					tax = parseFloat((total * (exclusivePercent / 100)).toFixed(2))
					price.exclusiveTaxTotal += tax
					item.taxType = 'exclusive'
					item.taxes = exclusiveTaxes
				} else if (inclusivePercent > 0) {
					const subTotal = parseFloat((
						total - total * (inclusivePercent / (100 + inclusivePercent))
					).toFixed(2))

					item.subTotal = subTotal
					price.subTotal += subTotal
					tax = parseFloat((total * (inclusivePercent / (100 + inclusivePercent))).toFixed(2))
					price.inclusiveTaxTotal += tax
					item.taxType = 'inclusive'
					item.taxes = inclusiveTaxes.map(tax => {
						return Object.assign({}, tax, {
							tax_amount: parseFloat((subTotal * (parseFloat(tax.tax_rate) / 100)).toFixed(2))
						})
					})
				} else {
					price.subTotal += total
					item.taxes = []
				}

				item.taxAmount = tax
				price.tax += tax

				if (item.taxes) {
					item.taxes.forEach(tax => {
						tax = Object.assign({}, tax)

						if (price.taxes[tax.tax_id])
							price.taxes[tax.tax_id].tax_amount += tax.tax_amount
						else
							price.taxes[tax.tax_id] = tax
					})
				}

				item.discount = item.item_discount.reduce((discounts, discount) => {
					if (discount.type === 'ITEM') {
						if ((
							discount.buy_condition_type === 'quantity' && item.quantity >= discount.buy_condition_value
						) || (discount.buy_condition_type === 'price' && (
							item.subTotal + item.taxAmount
						) >= discount.buy_condition_value)) {
							const quantity = item.quantity / discount.buy_condition_value

							if (item.quantity % discount.buy_condition_value === 0) {
								discount.quantity = quantity <= discount.get_discount_max_quantity ?
									quantity : discount.get_discount_max_quantity
							} else {
								discount.quantity = Math.floor(quantity <= discount.get_discount_max_quantity ?
									quantity : discount.get_discount_max_quantity)
							}

							discounts.push(discount)
						}
					} else {
						discounts.push(discount)
					}

					return discounts
				}, [])


				return item
			})

			// apply discount
			let leftOverDiscountRate = 0

			cartItems = cartItems.map(item => {
				// order discount
				if (vuexContext.state.selectedDiscount)
					item.discount.push({
						...vuexContext.state.selectedDiscount,
						quantity: item.quantity
					})
				else {
					const index = item.discount.findIndex(d => d.apply_discount_sub_total)

					if (index !== -1)
						item.discount.splice(index, 1)
				}

				// item discount
				item.discount.forEach(discount => {
					let discountedAmount = 0
					let discountedTax = 0
					let taxes = []
					let discountAmountRate = 0

					if (item.quantity >= discount.quantity) {
						if (discount.get_discount_type === 'percentage') {
							discountedAmount += item.subTotal > 0 ? parseFloat((
								((item.subTotal / item.quantity) * discount.quantity || item.quantity) * (
									discount.get_discount_value / 100
								)
							).toFixed(2)) : 0
							discountedTax += item.taxAmount > 0 ? parseFloat((
								((item.taxAmount / item.quantity) * discount.quantity || item.quantity) * (
									discount.get_discount_value / 100
								)
							).toFixed(2)) : 0
							taxes = item.taxes.map(tax => {
								tax.discounted_tax = tax.tax_amount > 0 ? parseFloat((
									((tax.tax_amount / item.quantity) * discount.quantity || item.quantity) * (
										discount.get_discount_value / 100
									)
								).toFixed(2)) : 0
								price.taxes[tax.tax_id].discounted_tax += tax.discounted_tax

								return tax
							})
						} else if (discount.get_discount_type === 'price') {
							const total = price.subTotal + price.tax
							const itemTotal = item.subTotal + item.taxAmount

							if (discount.get_discount_value < total) {
								discountAmountRate = parseFloat(itemTotal / total) + leftOverDiscountRate
								const totalDiscountedAmount = parseFloat((
									discount.get_discount_value * discountAmountRate
								).toFixed(2))

								discountedAmount += parseFloat((
									item.subTotal / itemTotal * totalDiscountedAmount
								).toFixed(2))
								discountedTax += parseFloat((
									item.taxAmount / itemTotal * totalDiscountedAmount
								).toFixed(2))

								if (!discount.apply_discount_sub_total) {
									discountedAmount += discountedAmount * (discount.quantity - 1)
									discountedTax += discountedTax * (discount.quantity - 1)
								}

								taxes = item.taxes.map(tax => {
									tax.discounted_tax = parseFloat((
										(tax.tax_amount / item.taxAmount) * discountedTax
									).toFixed(2))
									price.taxes[tax.tax_id].discounted_tax += tax.discounted_tax

									return tax
								})
							} else {
								discountedAmount += item.subTotal
								discountedTax += item.taxAmount
								taxes = item.taxes.map(tax => {
									tax.discounted_tax = tax.tax_amount
									price.taxes[tax.tax_id].discounted_tax += tax.discounted_tax

									return tax
								})
							}
						} else {
							return
						}
					}

					if (item.discountedAmount + item.discountedTax < item.subTotal + item.taxAmount) {
						item.discounts.push({
							...discount,
							discounted_amount: discountedAmount,
							discounted_tax: discountedTax
						})

						item.discountedAmount += discountedAmount
						item.discountedTax += discountedTax
						item.taxes = taxes
						price.discountedAmount += discountedAmount
						price.discountedTax += discountedTax
						price.discount += discountedAmount + discountedTax

						if (leftOverDiscountRate)
							leftOverDiscountRate = 0
					} else if (discount.get_discount_type === 'price') {
						leftOverDiscountRate += discountAmountRate
					}
				})

				return item
			})

			price.total = (price.subTotal + price.tax) - price.discount
			price.taxes = Object.values(price.taxes)

			if (+vuexContext.state.settings.general.round_off_total) {
				price.roundOff = parseFloat((Math.round(price.total) - price.total).toFixed(2))
				price.total = Math.round(price.total)
			}

			vuexContext.commit('setCart', { items: cartItems, price: price })

			if (!appliedAutoOrderDiscount) {
				vuexContext.dispatch('orderAutoDiscount')
			}
		},
	orderAutoDiscount(vuexContext) {
		let total = vuexContext.state.cart.price.total
		let cartItems = [...vuexContext.state.cart.items]
		const selectedDiscount = vuexContext.state.selectedDiscount ? JSON.parse(JSON.stringify(
					vuexContext.state.selectedDiscount
				)) : null

		if (vuexContext.state.selectedDiscount && vuexContext.state.selectedDiscount.is_automatic) {
			total += cartItems.reduce((discountSum, i) => {
				const index = i.discounts.findIndex(d => d.apply_discount_sub_total && d.is_automatic)

				if (index !== -1)
					discountSum += i.discounts[index].discounted_amount + i.discounts[index].discounted_tax

				return discountSum
			}, 0)

			vuexContext.commit('setState', {
				key: 'selectedDiscount',
				value: null
			})

		}

		const autoOrderDiscount = vuexContext.getters.autoOrderDiscount('price', total)
		const orderDiscountItemIndexes = cartItems.reduce((indexes, item, index) => {
			const discountIndex = item.discounts.findIndex(d => d.type === 'ORDER')

			if (discountIndex !== -1)
				indexes[index] = item.discounts[discountIndex].quantity

			return indexes
		}, {})

		if (autoOrderDiscount) {
			if (autoOrderDiscount.discount_items && autoOrderDiscount.discount_items.length) {
				const indexes = Object.keys(orderDiscountItemIndexes)

				for (let i = indexes.length - 1; i >= 0; i--) {
					const quantity = cartItems[indexes[i]].quantity - orderDiscountItemIndexes[indexes[i]]

					if (quantity <= 0){
						cartItems.splice(indexes[i], 1)
					}else{
						cartItems[indexes[i]].item_discount = []
					}
				}

				autoOrderDiscount.discount_items.forEach(d => {
					let variation = this.$bridge.getItemVariations(vuexContext.state.deviceId, JSON.stringify({
						id: d.variation_id
					}))

					variation = (typeof variation === 'string' ? JSON.parse(variation) : variation)[0]

					if (variation) {
						variation = variation
						const batch = variation.batch.find(b => b.id === d.get_batch_id) || null
						const cartItemIndex = cartItems.findIndex(iv => {
							return iv.id === variation.id && iv.batch_id === d.get_batch_id && iv.price === (
								batch ? batch.price : variation.price
							)
						})

						if (cartItemIndex !== -1) {
							cartItems[cartItemIndex].item_discount.push({
								...d,
								id: autoOrderDiscount.id,
								type: 'ORDER',
								quantity: d.get_discount_quantity
							})
						} else {
							cartItems.unshift({
								id: variation.id,
								item_id: variation.item_id,
								category_id: variation.item.category_id,
								inventory_id: variation.inventory_id,
								batch_id: d.get_batch_id,
								kot_device_id: variation.kot_device_id,
								name: variation.name,
								item_name: variation.item.name,
								alternate_name: variation.custom_attributes.alternate_language,
								sku: variation.sku,
								type: variation.type,
								barcode: variation.barcode,
								hsn: variation.custom_attributes.hsn || '',
								unit_measure_type: variation.unit_measure_type,
								mrp: batch ? batch.mrp : (variation.custom_attributes.mrp || 0),
								price: batch ? batch.price : variation.price,
								quantity: d.get_discount_quantity,
								tax: variation.tax,
								discount: [],
								item_discount: [{
									...d,
									id: autoOrderDiscount.id,
									type: 'ORDER',
									quantity: d.get_discount_quantity
								}],
								combo_discount: [],
								itemization_type: variation.itemization_type || 'item',
								groups: []
							})
						}
					}
				})

				vuexContext.commit('setCart', { items: cartItems })
				vuexContext.dispatch('cartCalculation', true)
			} else {
				if (Object.keys(orderDiscountItemIndexes).length) {
					const indexes = Object.keys(orderDiscountItemIndexes)

					for (let i = indexes.length - 1; i >= 0; i--) {
						cartItems.splice(indexes[i], 1)
					}

					vuexContext.commit('setCart', { items: cartItems })
				}

				vuexContext.commit('setState', {
					key: 'selectedDiscount',
					value: autoOrderDiscount
				})
				vuexContext.dispatch('cartCalculation', true)
			}
		} else {
			if (Object.keys(orderDiscountItemIndexes).length>0) {
				const indexes = Object.keys(orderDiscountItemIndexes)

				for (let i = indexes.length - 1; i >= 0; i--) {
					const quantity = cartItems[indexes[i]].quantity - orderDiscountItemIndexes[indexes[i]]

					if (quantity <= 0){
						cartItems.splice(indexes[i], 1)
					}else{
						cartItems[indexes[i]].item_discount = []
					}
				}

				vuexContext.commit('setCart', { items: cartItems })
				vuexContext.dispatch('cartCalculation')
			} else if (selectedDiscount && selectedDiscount.is_automatic === true) {
				vuexContext.commit('setState', {
					key: 'selectedDiscount',
					value: null
				})
				vuexContext.dispatch('cartCalculation')
			}
		}
	},
	uploadToS3 (vuexContext, body) {
		return this.$axios({
			method: 'post',
			url: '/api/s3/upload',
			data: body
		})
	},
	checkSubscription(vuexContext) {
		if (vuexContext.state.subscription) {
			const today = this.$moment(new Date())
			const endDate = this.$moment.utc(vuexContext.state.subscription.device ? vuexContext.state.subscription.device.expiry_date : vuexContext.state.subscription.end_date).local()
			const expiresInDays = endDate.diff(today, 'days')
			const expiresInSeconds = endDate.diff(today, 'seconds')

			vuexContext.commit('setState', {
				key: 'subscription',
				value: {
					...vuexContext.state.subscription,
					show_modal: expiresInDays <= 15,
					expires_in_days: expiresInDays,
					expires_in_seconds: expiresInSeconds
				},
				save: true
			})


			if (expiresInSeconds > 0 && expiresInSeconds <= 86400) {
				setTimeout(() => {
					vuexContext.dispatch('checkSubscription')
				}, expiresInSeconds * 1000)
			}
		}
	},
	getSubscription(vuexContext) {
		this.$axios.$get('/api/pos/v2/subscription').then(response => {
			vuexContext.commit('setState', {
				key: 'subscription',
				value: {
					...response
				},
				save: true
			})
			vuexContext.dispatch('checkSubscription')
		}).catch(err => console.error(err))
	},
	getMasters (vuexContext, query) {
		return this.$axios.$get('/api/pos/v2/resource/masters', {
			params: query
		})
	},
	getLoyaltyPoints (vuexContext, data) {
		return this.$axios.$get('/api/pos/v1/loyalty', {
			params: data
		})
	}
}

export default actions

<template>
	<ons-page>
		<v-ons-toolbar>
			<div class="left">
				<v-ons-back-button />
			</div>
			<div class="center">
				Clock {{ type.replace('clock', '') }}
			</div>
		</v-ons-toolbar>
		<v-ons-row class="passcode h-100">
			<v-ons-col
				width="100%"
				vertical-align="center"
				class="px-5"
			>
				<ul
					ref="passcodeUl"
					class="passcode-hidden-numbers my-4"
				>
					<li
						v-for="value in [1, 2, 3, 4, 5, 6]"
						:key="value"
						:class="{ 'active': passcode.length >= value }"
					/>
				</ul>
				<v-ons-row
					v-for="(row, index) in codes"
					:key="index"
				>
					<v-ons-col
						v-for="code in row"
						:key="code"
					>
						<v-ons-fab
							v-if="code"
							class="mb-3"
							@click="code === 'C' ? passcode = passcode.slice(0, -1) : (
								passcode.length < 6 ? appendCode(code) : null
							)"
						>
							<p class="m-0">
								{{ code }}
							</p>
						</v-ons-fab>
					</v-ons-col>
				</v-ons-row>
			</v-ons-col>
		</v-ons-row>
		<v-ons-alert-dialog
			:visible.sync="showLoading"
			:title="type"
			style=" text-align: center; text-transform:capitalize;"
		>
			<v-ons-progress-circular
				class="loader"
				indeterminate
				style="padding-bottom:10px"
			/>
		</v-ons-alert-dialog>
	</ons-page>
</template>

<script>
	import Employees from '~/components/employees'

	export default {
		name: 'Passcode',
		props: {
			type: {
				type: String,
				required: true
			}
		},
		data() {
			return {
				passcode: '',
				codes: [
					['1', '2', '3'],
					['4', '5', '6'],
					['7', '8', '9'],
					['C', '0', '']
				],
				showLoading: false
			}
		},
		computed: {
			pageStack: {
				get() {
					return this.$store.state.pageStack
				},
				set(pageStack) {
					this.$store.commit('setPageStack', pageStack)
				}
			},
			deviceId() {
				return this.$store.state.deviceId
			},
			locationId() {
				return this.$store.state.locationId
			},
			employee() {
				return this.$store.state.employee
			},
			cashDrawerShift() {
				return this.$store.state.cashDrawerShift
			},
			merchant() {
				return this.$store.state.merchant
			},
			employeePolicy() {
				return this.$store.getters.employeePolicy
			},
			printerSettings() {
				return this.$store.state.printerSettings
			},
			mainMenu: {
				get() {
					return this.$store.state.mainMenu
				},
				set(mainMenu) {
					this.$store.commit('setState', { key: 'mainMenu', value: mainMenu, save: false})
				}
			},
		},
		methods: {
			appendCode(code) {
				this.passcode += code
				if (this.passcode.length === 6) {
					if (this.passcode == this.employee.passcode) {
						this.showLoading = true
						setTimeout(() => {
							if (this.type === 'clockin'){
								this.clockin()
							}
							else if (this.type === 'clockout'){
								this.clockout()
							}
							else
								this.passcode = ''
						}, 1000)
					} else {
						this.passcode = ''
						this.$refs.passcodeUl.classList.add('animate-shake')
						setTimeout(() => {
							this.$refs.passcodeUl.classList.remove('animate-shake')
						}, 1000)
					}
				}
			},
			async clockin() {
				const date = new Date()
				const employee = Object.assign({}, this.employee)

				const shift = {
					id: date.valueOf(),
					employee_id: this.employee.id,
					location_id: this.locationId,
					device_id: this.deviceId,
					shift_code: `${this.deviceId}${this.locationId}${date.valueOf()}`,
					clock_in: this.$moment.utc(date).format('YYYY-MM-DD HH:mm:ss'),
					updated_at: date
				}

				employee.shiftId = shift.id
				employee.shiftCode = shift.shift_code

				this.$bridge.insert(
					'EmployeeShift',
					this.$bridge.getName() === 'ANDROID' ? JSON.stringify(shift) : shift,
					true
				)

				const syncData = {
					id: shift.id,
					model_id: shift.id,
					model_name: 'employee-shift',
					payload: JSON.stringify({
						model_id: shift.id,
						employee_id: shift.employee_id,
						location_id: shift.location_id,
						device_id: shift.device_id,
						shift_code: shift.shift_code,
						clockin_time: shift.clock_in
					})
				}

				this.$bridge.insert(
					'Sync', this.$bridge.getName() === 'ANDROID' ? JSON.stringify(syncData) : syncData,
					false
				)
				this.$store.commit('setState', {
					key: 'employee',
					value: employee,
					save: true
				})
				this.pageStack = [this.mainMenu[0].component]
				if (!this.cashDrawerShift) {
					this.$store.commit('setState', {
						key: 'cashDrawer',
						value: {
							show: true,
							close: false,
							type: 'starting'
						}
					})
				}
			},
			async clockout() {
				this.showLoading = true
				const date = new Date()
				const cashDrawerShift = Object.assign({}, this.cashDrawerShift)
				const employee = Object.assign({}, this.employee)

				this.$bridge.put(
					'CashDrawerShift',
					this.$bridge.getName() === 'ANDROID' ?
						JSON.stringify(cashDrawerShift) : cashDrawerShift)

				let syncData = {
					id: date.valueOf(),
					model_id: cashDrawerShift.id,
					model_name: 'cash-drawer-shift',
					payload: JSON.stringify({
						model_id: cashDrawerShift.id,
						device_id: this.deviceId,
						shift_code: cashDrawerShift.shift_code,
						cash_drawer_status: 'closed',
						employee_shift_code: employee.shiftCode,
						cash_drawer_shift_id: cashDrawerShift.cash_drawer_shift_id,
						closed_at: cashDrawerShift.closed_at,
						closing_employee_id: employee.id,
						closed_cash_money: cashDrawerShift.closed_cash_amount
					})
				}

				this.$bridge.put(
					'CashDrawerShiftSync',
					this.$bridge.getName() === 'ANDROID' ? JSON.stringify(syncData) : syncData
				)

				let shift = this.$bridge.getEmployeeShifts(
					this.deviceId,
					JSON.stringify({ id: parseInt(employee.shiftId) })
				)

				shift = typeof shift === 'string' ? JSON.parse(shift) : shift
				shift = shift ? shift[0] : null

				if (shift) {
					shift.clock_out = this.$moment.utc(date).format('YYYY-MM-DD HH:mm:ss')
					shift.updated_at = date
					this.$bridge.put(
						'EmployeeShift',
						this.$bridge.getName() === 'ANDROID' ? JSON.stringify(shift) : shift)

					syncData = {
						id: date.valueOf() + 1,
						model_id: shift.id,
						model_name: 'employee-shift',
						payload: JSON.stringify({
							model_id: shift.id,
							shift_code: shift.shift_code,
							clockout_time: shift.clock_out
						})
					}

					this.$bridge.put(
						'EmployeeShiftSync',
						this.$bridge.getName() === 'ANDROID' ? JSON.stringify(syncData) : syncData
					)
				}

				if (this.printerSettings.printShiftSummaryAfterClockout) {
					this.$store.commit('setState', {
						key: 'shiftReceipt',
						value: {
							print: true,
							source: 'clockout'
						}
					})
				} else {
					this.$store.commit('setState', {
						key: 'cashDrawerShift',
						value: null,
						save: false
					})
					this.$store.commit('setState', {
						key: 'employee',
						value: null,
						save: false
					})

					this.$bridge.removeLocalStorage('employee')
					this.$store.commit('resetPasscode')

				}
				this.pageStack = [Employees]
			}
		}
	}
</script>

<style lang="scss" scoped>
	.passcode {
		text-align: center;

		&-hidden-numbers {
			margin: 0;
			padding: 0 0 3rem 0;
			list-style: none;

			> li {
				display: inline-block;
				margin-right: 10px;
				border-radius: 50%;
				border: 2px solid #FF8C00;
				width: 25px;
				height: 25px;

				&:last-child {
					margin-right: 0;
				}

				&.active {
					background-color: #FF8C00;
				}
			}
		}
	}
	.loader {
		position: fixed;
	  left: 43%;
	  top: 50%;
		width: 48px;
		height: 48px;
	  z-index: 999999;
	}
</style>
